import React from 'react';

import { Challenge } from '@wix/ambassador-challenges-v1-challenge/types';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import { classes as classesGrid } from './ChallengeCardAsGrid.st.css';
import { classes as classesSide } from './ChallengeCardAsSideBySide.st.css';
import settingsParams from '../../settingsParams';
import { ILayoutType } from '../../Settings/challengeSettings/challengeSettings.types';

interface ChallengeSubtitleProps {
  displayParams: {
    duration: boolean;
    image: boolean;
    divider: boolean;
    price: boolean;
    participants: boolean;
  };
  showParticipants: boolean;
  durationString: string;
  challenge: Challenge;
}

export const ChallengeCardSubtitle: React.FC<ChallengeSubtitleProps> = ({
  displayParams,
  showParticipants,
  durationString,
  challenge,
}) => {
  const { t } = useTranslation();
  const settings = useSettings();

  const isGridLayout =
    settings.get(settingsParams.layoutType) === ILayoutType.Grid;
  const classes = isGridLayout ? classesGrid : classesSide;

  return (
    (displayParams.duration || showParticipants) && (
      <p className={classes.subtitle} data-hook="card-subtitle">
        {!!displayParams.duration && (
          <span className={classes.subtitleItem} data-hook="challenge-duration">
            {durationString}
          </span>
        )}
        {displayParams.duration && showParticipants && (
          <span className={classes.subtitleItem} data-hook="challenge-bullet">
            <>&nbsp;&bull;&nbsp;</>
          </span>
        )}
        {!!showParticipants && (
          <span
            className={classes.subtitleItem}
            data-hook="challenge-participants"
          >
            {t('challenge-card.participants_icu', {
              count: challenge.participantsSummary.participantsNumber,
            })}
          </span>
        )}
      </p>
    )
  );
};
